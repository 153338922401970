<template>
	<v-card class="overflow-hidden" elevation="10" align="start" height="365">
		<v-card-text>
			<div
				align="center"
				class="subtitle-1 font-weight-bold greyDarken4--text text-uppercase text-truncate"
			>
				{{ $t("app.your_groups") }}
			</div>
			<div align="center" class="text-truncate">
				{{ $t("app.your_groups_desc") }}
			</div>

			<div v-if="!list || !list.length" class="mt-15 pt-10" align="center">
				<v-img
					width="60"
					:src="require('@/assets/images/partners.png')"
				></v-img>
				<div class="mt-7 subtitle-1 greyDarken4--text">
					{{ $t("app.no_groups_yet") }}
				</div>
			</div>

			<v-list two-line tile v-else class="mt-5">
				<template v-for="(item, index) in list">
					<v-list-item :key="index">
						<v-list-item-icon>
							<v-icon color="info"
								>$vuetify.icons.values.google_circle_extended</v-icon
							>
						</v-list-item-icon>
						<v-list-item-content>
							<!-- Message owner -->
							<v-list-item-title>
								{{ item.name }}
							</v-list-item-title>

							<!-- Message subtitle -->
							<v-list-item-subtitle>{{
								item.project_name ? item.project_name : $t("app.project_name")
							}}</v-list-item-subtitle>
						</v-list-item-content>

						<v-list-item-action justify="center">
							<v-list-item-action-text>{{
								$t("app.total_students")
							}}</v-list-item-action-text>
							<v-list-item-action-text>
								{{ item.students_count }}
							</v-list-item-action-text>
						</v-list-item-action>
					</v-list-item>
				</template>
			</v-list>

			<v-footer absolute bottom color="white">
				<AppButton
					:label="
						list && list.length
							? 'app.view_all_groups'
							: 'app.start_creating_groups'
					"
					color="blueLighten5"
					:icon="$vuetify.rtl ? 'left_chevron' : 'right_chevron'"
					:block="true"
					:prop_class="['mb-1', 'font-weight-bold', 'info--text']"
					@click="redirectTo()"
				></AppButton>
			</v-footer>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: "DashboardGroups",

	data() {
		return {
			list: null,
		};
	},

	mounted() {
		this.getList();
	},

	methods: {
		async getList() {
			let response = await this.$store.dispatch("group/list");
			this.list = response.slice(0, 3);
		},

		redirectTo() {
			this.$router.push({
				name: "MentorGroups",
			});
		},
	},
};
</script>

<style></style>

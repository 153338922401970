<template>
	<v-container fluid>
		<v-card height="100%" flat class="wr_grey_1">
			<v-card-text>
				<v-row no-gutters>
					<!-- Profile info -->
					<v-col>
						<Introduction></Introduction>
					</v-col>
					<!-- Sign out -->
					<v-col>
						<TheLogout></TheLogout>
					</v-col>
				</v-row>
			</v-card-text>

			<!-- messages and meetings and groups -->
			<v-card-text class="mt-5">
				<v-row>
					<v-col xs="12" sm="6" md="4">
						<div>
							<Notifications></Notifications>
						</div>
					</v-col>
					<v-col xs="12" sm="6" md="4">
						<div>
							<Meetings></Meetings>
						</div>
					</v-col>
					<v-col xs="12" sm="6" md="4" class="mx-auto">
						<div class="me-2">
							<Groups></Groups>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import { mapGetters } from "vuex";
import TheLogout from "@/components/layout/TheLogout.vue";
import Notifications from "@/components/shared/dashboard/Notifications.vue";
import Meetings from "@/components/shared/dashboard/Meetings.vue";
import Introduction from "@/components/shared/dashboard/Introduction.vue";
import Groups from "@/components/shared/dashboard/Groups.vue";

export default {
	name: "MentorDashboardPage",

	components: {
		TheLogout,
		Notifications,
		Meetings,
		Introduction,
		Groups,
	},

	computed: {
		...mapGetters({
			userProfile: "user/getProfile",
			fullName: "user/fullName",
			getFirstName: "user/getFirstName",
		}),

		greetingName() {
			let first_name = this.getFirstName();
			return first_name ? first_name : this.fullName();
		},
	},
};
</script>
